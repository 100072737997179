<template>
  <el-dialog
    v-model="showAddPriceDialog"
    width="620px"
    align-center
    :destroy-on-close="true"
    :show-close="true"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-valuation-add-price-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <div class="elv-valuation-add-price-dialog-header-title">
        <h4 :id="titleId">
          {{ t('title.addPriceProvider') }}
        </h4>
      </div>
    </template>
    <div v-loading="isShowAddPriceLoading" class="elv-valuation-add-price-dialog-content">
      <el-form ref="projectFormRef" :model="customAddParams" :rules="rules" label-position="top">
        <el-form-item :label="t('valuation.price.providerName')" prop="name">
          <el-input
            v-model="customAddParams.providerName"
            minlength="1"
            maxlength="64"
            :placeholder="t('common.input')"
            width="572px"
          />
        </el-form-item>
        <el-form-item :label="t('valuation.price.priceRetrievalMethod')">
          <ElvSelect
            v-model="customAddParams.method"
            :teleported="false"
            width="572px"
            :placeholder="t('common.input')"
            :options="methods"
          />
          <div class="elv-valuation-add-price-dialog-content-tip-info">
            <SvgIcon name="help" :width="12" :height="12" :fill="'#AAAFB6'" />
            {{ customAddParams.method === 'CSV' ? t('desc.addCsvPriceTipInfo') : t('desc.addOpenApiPriceTipInfo') }}
            <span v-show="customAddParams.method === 'OPENAPI'" @click="jumpToOpenApiLink">{{
              t('button.APIDocument')
            }}</span>
          </div>
        </el-form-item>
        <el-form-item :label="t('common.timezone')" prop="timezone">
          <el-select
            v-model="customAddParams.timeZone"
            filterable
            default-first-option
            :placeholder="t('common.select')"
            :suffix-icon="SelectSuffixIcon"
          >
            <el-option
              v-for="(item, index) in timezoneList"
              :key="index"
              :label="`${item.area} (${item.timezone})`"
              :value="item.area"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="t('valuation.price.quote')" prop="quote">
          <ElvSelect
            v-model="customAddParams.quote"
            :teleported="false"
            filterable
            remote
            remote-show-suffix
            :loading="searchLoading"
            width="572px"
            :remote-method="remoteCurrencyMethod"
            :placeholder="t('common.select')"
            :options="currencyList"
            @change="onChangeCurrency()"
            @visible-change="changeSelectPopover"
          />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <div class="elv-add-tip-assets-dialog-footer-content">
        <el-button
          type="primary"
          round
          :loading="isAddLoading"
          class="elv-add-assets-dialog-footer__confirm"
          :class="{ 'elv-Account-delete-dialog-footer__cancel': isUnEnableAdd }"
          @click="onClickConfirmSavePriceProvider"
        >
          {{ t('button.add') }}</el-button
        >
      </div>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import ConfigApi from '@/api/ConfigApi'
import { cloneDeep, trim } from 'lodash-es'
import timezoneList from '@/config/timezone'
import ValuationApi from '@/api/ValuationApi'
import { ElMessage, FormRules } from 'element-plus'
import ElvSelect from '@/components/Base/ElvSelect.vue'
import { useEntityStore } from '@/stores/modules/entity'
import SelectSuffixIcon from '@/components/Project/SelectSuffixIcon.vue'

type OptionType = {
  id?: string
  value: string
  label: string
  icon?: string
  alt?: string
  isCheck?: boolean
  isDeleted?: boolean
  suffixName?: string
  formatDetailPlatformsList?: []
}

const emit = defineEmits(['onClickConfirmHandler'])

const { t } = useI18n()
const entityStore = useEntityStore()

const isAddLoading = ref(false)
const searchLoading = ref(false)
const showAddPriceDialog = ref(false)
const isShowAddPriceLoading = ref(false)
const currencyList = ref<OptionType[]>([])
const currentCurrencyData = ref<OptionType>()
const recommendCurrencyList = ref<OptionType[]>([])
const methods = ref<OptionType[]>([
  {
    value: 'CSV',
    label: 'CSV'
  },
  {
    value: 'OPENAPI',
    label: t('common.openApi')
  }
])

const rules = reactive<FormRules>({
  timezone: {
    required: true,
    trigger: 'blur',
    message: 'Timezone is required'
  },
  quote: {
    required: true,
    trigger: 'blur',
    message: 'Quote is required'
  }
})

const customAddParams = reactive({
  providerName: '',
  method: methods.value[0].value,
  timeZone: entityStore.entityDetail?.timezone,
  quote: ''
})

const entityId = computed(() => {
  return String(entityStore.entityDetail?.entityId)
})

const isUnEnableAdd = computed(() => {
  return !customAddParams.providerName || !customAddParams.method || !customAddParams.timeZone || !customAddParams.quote
})

/**
 * 关闭添加价格源弹窗
 */
const onCloseDialog = () => {
  customAddParams.providerName = ''
  customAddParams.method = ''
  customAddParams.timeZone = ''
  customAddParams.quote = ''
}

/**
 * 获取币种信息
 * @param params 获取币种信息参数
 * @property params.recommend 是否推荐币种
 */
const getRecommendCurrency = async (params: any) => {
  const searchList = await ConfigApi.searchCurrencyList(params)
  currencyList.value = searchList.data.map((item) => ({
    id: item.symbol,
    value: item.symbol,
    label: item.showSymbol,
    suffixName: item.showSymbol,
    icon: item.logo,
    alt: item.logo || '',
    formatDetailPlatformsList: item?.formatDetailPlatforms || [],
    isCheck: false
  }))
  if (params?.recommend) {
    recommendCurrencyList.value = cloneDeep(currencyList.value)
  }
}

/**
 * 切换币种信息
 */
const onChangeCurrency = () => {
  const currentCurrenciesInfo = currencyList.value?.find((item) => item.value === customAddParams.quote)
  currentCurrencyData.value = currentCurrenciesInfo
}

/**
 * 选择币种选择器弹窗显示状态
 * @param showStatus 显隐状态
 */
const changeSelectPopover = (showStatus: boolean) => {
  if (!showStatus && customAddParams.quote) {
    const isHasSelectCurrency = currencyList.value.some((item) => item.value === customAddParams.quote)
    if (!isHasSelectCurrency && customAddParams.quote && currentCurrencyData.value) {
      const info: any = cloneDeep(currentCurrencyData.value)
      currencyList.value.unshift(info)
    }
  }
}

/**
 * 远程搜索币种信息
 * @param query 币种名称
 */
const remoteCurrencyMethod = async (query: string) => {
  if (query) {
    searchLoading.value = true
    const params: any = {
      recommend: false,
      type: 'FIAT',
      entityId: 0,
      keywords: [query]
    }
    try {
      await getRecommendCurrency(params)
    } catch (error) {
      console.log(error)
    } finally {
      searchLoading.value = false
    }
  } else {
    if (!currencyList.value.length) {
      await getRecommendCurrency({ recommend: true, type: 'FIAT' })
    }
    currencyList.value = cloneDeep(recommendCurrencyList.value)
  }
}

/**
 * 确认提交价格源信息
 */
const onClickConfirmSavePriceProvider = async () => {
  isAddLoading.value = true
  const addParam = {
    name: trim(customAddParams.providerName),
    type: customAddParams.method,
    timezone: customAddParams.timeZone,
    quoteCurrency: customAddParams.quote
  }
  try {
    await ValuationApi.addPriceProviderInfo(entityId.value, addParam)
    ElMessage.success(t('message.addAssetSuccessfully'))
    emit('onClickConfirmHandler')
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    isAddLoading.value = false
  }
}

/**
 * 打开添加价格源弹窗
 */
const changeDialogStatus = async () => {
  showAddPriceDialog.value = !showAddPriceDialog.value
  if (showAddPriceDialog.value) {
    isShowAddPriceLoading.value = true
    try {
      await remoteCurrencyMethod('')
      customAddParams.quote = currencyList.value[0]?.value || ''
      customAddParams.method = methods.value[0].value
      customAddParams.timeZone = entityStore.entityDetail?.timezone
    } catch (error: any) {
      console.log(error.message)
    } finally {
      isShowAddPriceLoading.value = false
    }
  }
}

/**
 * 跳转至OpenApi链接
 */
const jumpToOpenApiLink = () => {
  window.open(
    'https://docs.elven.com/english/~/changes/bOPUH7KDpqjfKgZpFTN5/openapi/page?r=mS8lG8UnmEavKG0NJa06',
    '_blank'
  )
}

defineExpose({
  changeDialogStatus
})
</script>

<style lang="scss">
.elv-valuation-add-price-dialog {
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    border-bottom: 1px solid #edf0f3;
    padding: 0px;
  }

  .elv-valuation-add-price-dialog-header-title {
    min-height: 54px;
    text-align: center;
    font-size: 16px;
    color: #0e0f11;
    line-height: 24px;
    height: 49px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 24px;
  }

  .elv-valuation-add-price-dialog-content {
    padding: 18px 24px 0px 24px;
    position: relative;

    .elv-base-extend-select {
      .elv-base-extend-select-popper {
        .el-select-dropdown__wrap {
          ul > li {
            padding: 0px 8px;
          }
        }

        .el-select-dropdown__footer {
          padding: 0px;
        }
      }
    }

    .el-form-item {
      label {
        pointer-events: none;
      }
    }

    .elv-valuation-add-price-dialog-content-tip-info {
      display: flex;
      align-items: center;
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 12px;
      color: #636b75;
      height: 15px;
      margin-top: 8px;

      svg {
        margin-right: 4px;
      }

      span {
        display: flex;
        align-items: center;
        cursor: pointer;
        text-decoration: underline;
        margin-left: 4px;
        height: 15px;
      }
    }
  }

  .elv-add-tip-assets-dialog-footer-content {
    min-height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;

    .el-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      width: 113px;
      background: #1753eb;
      border-radius: 22px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #fff;
      border: 0px;
    }

    .elv-Account-delete-dialog-footer__cancel {
      background: #edf0f3;
      color: #636b75;
      pointer-events: none;
    }

    .elv-Account-delete-dialog-footer__delete {
      margin-left: 20px;
    }
  }
}
</style>
